import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"

const CEU = () => (
	<Layout>
		<SEO title="CEUs" description="continuing education" />
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h5 class="font-weight-bold text-uppercase text-orange flair mb-0">
						CEUs
					</h5>
					<h1>Continuing Education</h1>
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div className="intro mb-4 ">
					<div className="row">
						<div className="col-md-9 mx-auto text-center">
							<h2 className="text-primary">
								<span className="text-orange">Continuing </span>Education
							</h2>
							<p className="text-muted">
								{" "}
								<strong>
									CONTINUING EDUCATION (120 hours per training)
								</strong>{" "}
							</p>
							<hr />
						</div>
					</div>
				</div>
				<div class="row justify-content-between align-items-center">
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							{" "}
							<strong>Satisfactory Completion:</strong> Participants must have
							paid tuition fee, signed in, attended the entire seminar,
							completed an evaluation, and signed out in order to receive a
							certificate. Failure to sign in or out will result in forfeiture
							of credit for the entire course. No exceptions will be made.
							Partial credit is not available. Certificates available following
							course completion at www.ceuregistration.com. CE purchase link
							provided after completion of each module. CE Credit is available
							for live attendance only.
						</p>
					</div>
				</div>
				<div class="row justify-content-between align-items-center pt-5">
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							{" "}
							<strong>Psychologists:</strong> R. Cassidy Seminars is approved by
							the American Psychological Association (APA) to offer continuing
							education for psychologists. R. Cassidy Seminars maintains
							responsibility for this program.
						</p>
					</div>
				</div>
				<div className="intro mb-4 ">
					<div className="row">
						<div className="col-md-12 mx-auto ">
							<h2 className="text-primary text-center">
								<span className="text-orange">Social Workers</span>
							</h2>
							<p className="text-left">
								{" "}
								<strong>CA:</strong> The Board of Behavioral Sciences has
								deferred CE course approvals to APA and other states’ board
								approvals for its licensees. See those approvals under
								Psychologists and Social Workers, and Counselors/MFTs.
							</p>
							<p className="text-left">
								{" "}
								<strong>NY:</strong> R. Cassidy Seminars is recognized by the
								New York State Education Department's State Board for Social
								Work as an approved provider of continuing education for
								licensed social workers #SW-0006.
							</p>
							<p className="text-left">
								{" "}
								<strong>OH:</strong> Provider approved by the Ohio Counselor,
								Social Worker and Marriage and Family Therapist Board for 120
								clock hours, #RCST110701
							</p>
							<hr />
						</div>
					</div>
				</div>
				<div className="intro mb-4 ">
					<div className="row">
						<div className="col-md-12 mx-auto ">
							<h2 className="text-primary text-center">
								Counselors/Marriage and Family Therapists
								<span className="text-orange" />
							</h2>
							<p className="text-left">
								{" "}
								<strong>CA:</strong> The Board of Behavioral Sciences has
								deferred CE course approvals to APA and other states’ board
								approvals for its licensees. See those approvals under
								Psychologists and Social Workers.
							</p>
							<p className="text-left">
								{" "}
								<strong>Other States:</strong> If your state is not specifically
								listed, nearly all state Counselor and MFT boards accept either
								APA, or are reciprocal with other state licensing board
								approvals, such as those listed below. Check with your board to
								be sure. The Ohio Board includes Counselors and MFTs.
							</p>
							<p className="text-left">
								{" "}
								<strong>IL:</strong> Illinois Dept of Professional Regulation,
								Approved Continuing Education Sponsor, #168-000141.
							</p>
							<p className="text-left">
								{" "}
								<strong>OH:</strong> Provider approved by the Ohio Counselor,
								Social Worker and Marriage and Family Therapist Board
								#RCST110701
							</p>
							<p className="text-left">
								{" "}
								<strong>TX:</strong> Approved CE Sponsor through the Texas State
								Board of Examiners of Marriage & Family Therapists. Provider
								#151
							</p>
							<hr />
						</div>
					</div>
				</div>
				<div className="intro mb-4 ">
					<div className="row">
						<div className="col-md-12 mx-auto ">
							<h2 className="text-primary text-center">
								<span className="text-orange">
									Chemical Dependency Counselors
								</span>
							</h2>
							<p className="text-left">
								{" "}
								<strong>CA:</strong> Provider approved by CCAPP, Provider
								#4N-00-434-0220. CCAPP is an ICRC member which has reciprocity
								with most ICRC member states
							</p>
							<p className="text-left">
								{" "}
								<strong>TX:</strong> Provider approved by the TCBAP Standards
								Committee, Provider No. 1749-06, for general hours. Expires
								3/31/2020. Complaints about provider or workshop content may be
								directed to the TCBAP Standards Committee, 1005 Congress Avenue,
								Ste. 460, Austin, Texas 78701, Fax Number (512) 476-7297.
							</p>

							<hr />
						</div>
					</div>
				</div>
				<div className="intro mb-4 ">
					<div className="row">
						<div className="col-md-12 mx-auto ">
							<h2 className="text-primary text-center">
								Educators
								<span className="text-orange" />
							</h2>
							<p className="text-left">
								{" "}
								<strong>TX:</strong> R. Cassidy Seminars is an approved provider
								with the Texas Education Agency CPE# 501456.
							</p>

							<hr />
						</div>
					</div>
				</div>
				<div className="intro mb-4 ">
					<div className="row">
						<div className="col-md-12 mx-auto ">
							<h2 className="text-primary text-center">
								<span className="text-orange">Nurses</span>
							</h2>
							<p className="text-left">
								{" "}
								<strong>CA:</strong> Provider approved by the CA Board of
								Registered Nursing, Provider #CeP122.
							</p>
							<p className="text-left">
								{" "}
								<strong>Disability Access:</strong> If you require ADA
								accommodations please contact our office 30 days or more before
								the event. We cannot ensure accommodations without adequate
								prior notification.
							</p>
							<p className="text-left">
								{" "}
								<strong>Please Note:</strong> Licensing Boards change
								regulations often and while we attempt to stay abreast of their
								most recent changes, if you have questions or concerns about
								this course meeting your specific board’s approval, we recommend
								you contact your board directly to obtain a ruling.
							</p>

							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default CEU
